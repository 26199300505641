<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="text-center">
          <h2 v-if="language == 'en'" class="text-primary">
            PRIVACY POLICY OF AEQUITEX AG
          </h2>
          <h2 v-else class="text-primary">
            DATENSCHUTZERKLÄRUNG DER AEQUITEX AG
          </h2>
          <!-- AG VERSION 1.0 – NOVEMBER 22, 2021 -->
          <!-- form -->

          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <div>
          <b-card class="col-md-9"
            ><br /><br />
            <div class="col-md-12">
              <h4 class="row justify-content-between">
                <div v-html="language == 'en' ? 'ABOUT US' : 'ÜBER UNS'"></div>
                <div class="row">
                  <b-link
                    :class="
                      language == 'en' ? 'active-lang nav-link' : 'nav-link'
                    "
                    @click="toggleLanguage('en')"
                  >
                    <img src="/icons/en.png" class="flag-icon" />
                  </b-link>
                  <b-link
                    :class="
                      language == 'de' ? 'active-lang nav-link' : 'nav-link'
                    "
                    @click="toggleLanguage('de')"
                  >
                    <img src="/icons/de.png" class="flag-icon" />
                  </b-link>
                </div>
              </h4>
            </div>
            <br />
            <en v-if="language == 'en'"></en>
            <de v-if="language == 'de'"></de>
            <b-row align="end" class="justify-content-end col-md-12">
              <b-button variant="primary">
                <a
                  href="/files/privacy_de.docx"
                  v-if="language == 'de'"
                  class="text-white"
                  >Download</a
                >

                <a href="/files/privacy_en.docx" v-else class="text-white"
                  >Download</a
                >
              </b-button>
            </b-row>
          </b-card>
        </div>
        <!-- payment tab -->

        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <!-- <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          /> -->
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import En from "./PrivacyEn.vue";
import De from "./PrivacyDe.vue";
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    En,
    De,
  },
  data() {
    return {
      language: "en",
      options: {
        title: this.$t("faq_title"),
        subtitle: this.$t("lets_answer_some_questions"),
        icon: "creditCardIcon",
        qandA: [
          {
            question: this.$t("first_faq_question"),
            ans: this.$t("first_faq_answer"),
          },
          {
            question: this.$t("second_faq_question"),
            ans: this.$t("second_faq_answer"),
          },
          {
            question: this.$t("third_faq_question"),
            ans: this.$t("third_faq_answer"),
          },
          {
            question: this.$t("fourth_faq_question"),
            ans: this.$t("fourth_faq_answer"),
          },
        ],
      },
      faqSearchQuery: "",
      faqData: {},
      faq: {
        icon: "",
        title: "testt",
        subtitle: "anss",
      },
    };
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    toggleLanguage(language) {
      this.language = language;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
